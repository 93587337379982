import React from "react";
import { useStoryblokState, storyblokEditable } from "gatsby-source-storyblok";
import { graphql } from "gatsby";
import Page from "../components/Page";
import Layout from "../components/Layout";
import NewsItems from "../components/NewsItems";
import { getActiveLanguage } from "../utils/helper";
import Paginator from "../components/Paginatior";

export default function NewsPageMain({
  data,
  pageContext,
  location,
  ...props
}) {
  const story = useStoryblokState(data?.main, location);
  const blok = story?.content
  return (
    <Layout location={location} slug={'news'} full_slug={pageContext.pathPrefix} {...storyblokEditable(blok)}>
      {pageContext?.pageNumber > 0 && <>
        <div className="newsPaginationBanner">
          <div className="container">
            <h4 className="bannerSubTitle">
              {blok?.Title}
            </h4>
          </div>
            <Paginator
              total={pageContext.numberOfPages}
              curPage={pageContext.pageNumber}
              next={pageContext.nextPagePath}
              prev={pageContext.previousPagePath}
              pathPrefix={pageContext.pathPrefix}
            />
         
        </div>
      </>}
      <NewsItems
        lang={getActiveLanguage(location)}
        posts={data.posts}
        pageCount={pageContext.pageNumber}
        blok={blok}
      />

      <Paginator
        total={pageContext.numberOfPages}
        curPage={pageContext.pageNumber}
        next={pageContext.nextPagePath}
        prev={pageContext.previousPagePath}
        pathPrefix={pageContext.pathPrefix}
      />

    </Layout>
  );
}

export const query = graphql`
  query PostListQuery($skip: Int!, $limit: Int!, $lang: String!) {
    posts: allStoryblokEntry(
      filter: { field_component: { eq: "Post" }, lang: { eq: $lang } }
      sort: { fields: field_Published_Date_string, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          uuid
          name
          slug
          field_component
          full_slug
          content
          lang
        }
      }
    }
    main: storyblokEntry(
      field_component: { eq: "News-Page" }
      lang: { eq: $lang }
    ) {
      id
      uuid
      name
      slug
      field_component
      full_slug
      content
      lang
    }
  }
`;
