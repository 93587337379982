import { Link } from "gatsby";
import React from "react";
import "./paginatior.scss"
import prevImg from "../../images/left-a.png"
import nextImg from "../../images/right-a.png"

const Paginator = ({ total, curPage, next, prev, pathPrefix }) => {
  let list = [];
  for (var i = 0; i < total; i++) {
    list.push(
      <li className={curPage==i?"active":''}>
        <Link to={`${pathPrefix}/${i > 0 ? i + 1 : ""}`}><span>{i+1}</span></Link>
      </li>
    );
  }
  return (
    <>
        <section className="paginationSection">
          <div className="container">
            
            <div className="paginationContent">
                <div className="pagLink prev">
                  <Link to={prev}><img src={prevImg} alt="" /></Link>
                </div>
                <ul>{list}</ul>
                <div className="pagLink next">
                  <Link to={next}><img src={nextImg} alt="" /></Link>
                </div>
            </div>
          </div>
        </section>  
    </>
  );
};

export default Paginator;
